html,
body {
    height: 100%;
}
body {
    margin: 0;
    overflow: hidden;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/** Scrollable **/
$bg-color-scroll: #aab2bf;
$bg-color-scroll-2: #d9d9d9;
$border-radius-scroll: 4px;
$width-scroll: 5px;
$height-scroll: 5px;

@mixin scroll-custom {
    &::-webkit-scrollbar {
        width: $width-scroll;
        height: $height-scroll;
    }
    &::-webkit-scrollbar-thumb {
        background: $bg-color-scroll;
        border-radius: $border-radius-scroll;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 $border-radius-scroll $bg-color-scroll-2;
        border-radius: $border-radius-scroll;
    }
    scrollbar-color: $bg-color-scroll $bg-color-scroll-2;
    scrollbar-width: thin;
}
.custom-scrollbar {
    @include scroll-custom();
}
/** end scroll **/

/** pdfviewer **/

.fv__ui-pdfviewer {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    height: 100%;
}

/** end pdfviewer **/

/** dialog **/
.container-dialog-content-custom {
    pointer-events: auto;
    & > * {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0px 5px 8px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
    }
}
.confirm-field-matched-component {
    max-width: 450px;
    min-width: 400px;
    height: auto;
    max-height: 700px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px 1fr 50px;
    background: #ffffff;
    & > .confirm-field-matched-component-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 24px;
        & > span {
            color: #389e0d;
        }
    }
    & > .confirm-field-matched-component-content {
        padding: 10px 8px 20px 8px;
        min-height: 50px;
        & > .confirm-field-matched-component-content-input {
            max-width: 300px;
            flex: 1 1 auto;
            height: 50px;
            max-width: 400px;
            border-radius: 4px !important;
            border: unset !important;
            background-color: rgba($color: #aab2bf, $alpha: 0.2) !important;
            & > input {
                background-color: transparent !important;
            }
        }
    }
    & > .confirm-field-matched-component-footer {
        display: flex;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        & > * {
            height: 100%;
            flex: 1 1 auto;
            font-weight: 600;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0;
                color: #389e0d;
            }
        }
    }
}
.confirm-dialog-yes-no {
    width: 350px;
    height: auto;
    max-height: 150px;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px 1fr 45px;
    background: #ffffff;
    & > .confirm-dialog-yes-no-title {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 16px 24px;
    }
    & > .confirm-dialog-yes-no-content {
        padding: 10px 8px 20px 8px;
        min-height: 50px;
        text-align: center;
    }
    & > .confirm-dialog-yes-no-footer {
        display: flex;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        & > * {
            height: 100%;
            flex: 1 1 auto;
            font-weight: 600;
            &:not(:last-child) {
                border-right: 1px solid #f0f0f0;
                color: crimson;
            }
        }
    }
}
/** end dialog **/
.ant-steps-vertical .ant-steps-item-title {
    display: flex;
    justify-content: space-between;
    width: 85%;
}
.ant-popover-buttons {
    display: flex;
    justify-content: center;
}
.ellipsis-three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tb-review {
    cursor: pointer;
    color: #389e0d;
}
